<template>
    <div :class="['field-date-picker',{'hide-icon': !showDefaultIcon}]">
        <input type="hidden"
            :name="name"
            v-validate="rules"
            v-model="selectedValue"
            :data-vv-as="validationName"
            :data-vv-scope="scope">
        <date-picker
            v-model="selectedValue"
            :type="type"
            :format="format"
            :input-class="classControl"
            :placeholder="placeholder"
            :value-type="valueType"
            :disabled-date="disabledDateFormat"
            :disabled="disabled"
            :range="range"
            :confirm="confirm"
            :shortcuts="shortcutButton"
            :inline="inline"
            :editable="editable"
            :clearable="clearable"
            @change="change"
            @calendar-change="calendarChange"
        >
        </date-picker>
        <small v-if="!validationMin" class=" kt-font-danger" v-show="errors.first(errorField)">{{errors.first(errorField)}}</small>
    </div>
</template>

<script>
    import { Validator } from 'vee-validate';
    import DatePicker from 'vue2-datepicker';
    import MaxMinDate from "@/constants/date-range-enum"
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    export default {
        inject: ["$validator"],
        components: {
            DatePicker,
        },
        data() {
            return {
                rules: {required: false, dateBetweens: false},
                shortcutToday : [{
                    text: 'Today',
                    onClick() {
                        const date = new Date();
                        return date;
                    }
                }],
            }
        },
        mounted() {
            Validator.extend("dateBetweens", {
                validate: (value) => {
                    const dateNow = moment().format('YYYY-MM-DD')
                    return moment(value).isBetween(dateNow, MaxMinDate.MAX_DATE, null, [])
                },
                getMessage: () => 'Minimal hari ini'
            });
            this.rules.required = this.validation ? true: false
            this.rules.dateBetweens = this.validationMin
        },
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: [Array, Number, String, Object],
            validation:{
                type: [String, Object],
                default:''
            },
            validationMin: {
                type: Boolean,
                default: false
            },
            validationName:{
                type: String,
                default: 'Date Picker',
            },
            name:{
                type:String,
                default: 'datepicker'
            },
            scope: {
                type: String,
                default:null
            },
            format: {
                type: String,
                default:'DD-MM-YYYY'
            },
            placeholder: {
                type: String,
                default:'DD-MM-YYYY'
            },
            valueType: {
                type:String,
                default:'YYYY-MM-DD'
            },
            disabledDate: [String, Function],
            type: {
                type:String,
                default:'date'
            },
            disabled: {
                type:Boolean,
                default: false
            },
            range: {
                type:Boolean,
                default: false
            },
            confirm: {
                type:Boolean,
                default: false
            },
            shortcuts: {
                type: Array,
                default: ()=>[]
            },
            buttonToday: {
                type: Boolean,
                default: false
            },
            inline: {
                type: Boolean,
                default: false
            },
            inputClass:{
                type: [String, Array],
                default: 'form-control'
            },
            errorClass:{
                type:String,
                default: 'form-control is-invalid'
            },
            customClass: [String, Array, Function, Object],
            change:{
                type:Function,
                default: () => {}
            },
            eventCalendarChange:{
                type:Function,
                default: () => {}
            },
            editable: {
                type:Boolean,
                default: true
            },
            clearable: {
               type:Boolean,
                default: true
            },
            showDefaultIcon: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            selectedValue: {
                get () {
                    return this.value
                },
                set (newValue) {
                    this.$emit('change', newValue);
                }
            },
            errorField: function(){
                 return (this.scope != null) ? `${this.scope}.${this.name}` : this.name
            },
            shortcutButton: function(){
                var data = [];
                if(this.buttonToday){
                    data = [...data,... this.shortcutToday];
                }
                return [...data,...this.shortcuts];
            },
            classControl: function(){
                if(this.customClass != undefined || this.customClass != null){
                    return this.customClass
                } else {
                    return this.errors.has(this.errorField) ? this.errorClass : this.inputClass;
                }
            }
        },
        methods: {
            notBeforeToday(date) {
                return date < today;
            },
            notAfterToday(date) {
                return date > today;
            },
            notBeforeMonth(date){
                return moment(date).isBefore(moment(),'month');
            },
            notAfterMonth(date){
                return moment(date).isAfter(moment(),'month');
            },
            disabledDateFormat(date){
               if(typeof this.disabledDate == "function"){
                    const disabled = this.disabledDate(date);
                    return disabled
                } else if (this.disabledDate == "notBeforeToday"){
                    return this.notBeforeToday(date);
                } else if (this.disabledDate == "notAfterToday"){
                    return this.notAfterToday(date);
                } else if (this.disabledDate == "notBeforeMonth"){
                    return this.notBeforeMonth(date);
                } else if (this.disabledDate == "notAfterMonth"){
                    return this.notAfterMonth(date);
                }
            },
            calendarChange(date){
                if (this.eventCalendarChange) {
                    if (typeof this.eventCalendarChange == 'function') {
                        this.eventCalendarChange(date);
                    }
                }
            }
        },
    }
</script>

<style scoped>

.field-date-picker.hide-icon .mx-input-wrapper .mx-icon-calendar {
    display: none !important;
}
</style>
